import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UPLOAD_VIDEO } from "../../apollo/mutations";
import { Loading } from "../UI";

export const VideoUpload = (props) => {
  const formik = props.formik;

  const name = props.header ? props.header : "videoUrl";
  const videoTitle = props.videoTitle ? props.videoTitle : "Workout Video";
  const [src, setSrc] = useState("");
  const [fallback, setFallback] = useState(false);
  const reloadSrc = (e) => {
    if (fallback) {
      e.target.src = src;
    } else {
      e.target.src = src;
      setFallback(true);
    }
  };
  const [videoUploadMutation, { loading, error }] = useMutation(UPLOAD_VIDEO, {
    onCompleted({ uploadVideo }) {
      formik.setFieldValue(name, uploadVideo.url);
    },
  });

  useEffect(() => {
    setSrc(formik.values.videoUrl);
  }, [props]);

  if (error) {
    console.log(error);
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const fileHandler = ({
    target: {
      files: [file],
    },
  }: any) => {
    if (!file) {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }

    if (file) {
      videoUploadMutation({ variables: { file } });
    }
  };

  const videoHandler = ({ target: { duration } }: any) => {
    if (duration) {
      formik.setFieldValue("length", Math.floor(duration));
    } else {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }
  };

  return (
    <Container>
      <div>
        <h3>{videoTitle}</h3>
        <div>
          <VideoContainer
            controls
            onLoadedMetadata={videoHandler}
            key={formik.values.videoUrl}
          >
            <source src={formik.values.videoUrl} onError={reloadSrc} />
          </VideoContainer>
          <UploadContainer className="image-details">
            <VideoUploadLabel htmlFor="videoFiles">
              <p>Video Upload</p>
            </VideoUploadLabel>
            <input
              id="videoFiles"
              type="file"
              accept="video/*"
              onChange={fileHandler}
              style={{ visibility: "hidden" }}
            />
          </UploadContainer>
        </div>
      </div>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  display: flex;
  min-width: 250px;
  min-height: 250px;
`;

const VideoContainer = styled.video`
  max-width: 350px;
`;

const LoadingContainer = styled.div`
  width: 250px;
  height: 250px;
`;
const UploadContainer = styled.div<UploadedProps>``;

const VideoUploadLabel = styled.label`
  align-items: center;
  border: none;
  width: 150px;
  line-height: 43px;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  display: block;
  p {
    color: #ffffff;
    text-align: center;
  }
`;
