import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import styled from "styled-components";
import { UPDATE_CATEGORY_ORDERS } from "../../../apollo/mutations";
import { GET_CATEGORIES } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { EmptyState } from "../../Segments/EmptyState";
import { Body, Col, Head, Row, TEXT_INPUT } from "../../UI";
import { NonSortableCategory, SortableCategory } from "./Category";

export const Categories = () => {
  const [activeCategories, setActiveList] = useState([]);
  const [inActiveCategories, setInactiveList] = useState([]);

  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    onCompleted: ({ categories }) => {
      setActiveList(categories.filter((el: any) => el.isActive === true));
      setInactiveList(categories.filter((el: any) => el.isActive === false));
    },
    fetchPolicy: "network-only",
  });

  const [sortCategories, { loading: loadingOrders }] = useMutation(
    UPDATE_CATEGORY_ORDERS,
    {
      variables: {
        data: [
          ...activeCategories.map((el: any) => el.id),
          ...inActiveCategories.map((el: any) => el.id),
        ],
      },
      onCompleted(data) {
        console.log(data);
      },
    }
  );

  if (loading) return <p>loading ...</p>;
  if (error) return `Error! ${error}`;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMove(activeCategories, oldIndex, newIndex);
    setActiveList(sorted);
    sortCategories();
  };

  const SortableList = SortableContainer(({ items }) => {
    // const sortable = items.filter((el) => el.isActive === true);
    return (
      <Row>
        {items.map((value, index) => {
          return (
            <SortableCategory
              key={`item-${value.id}`}
              index={index}
              value={value}
              idx={index}
            />
          );
        })}
      </Row>
    );
  });

  const NonSortableList = ({ items }) => {
    {
      return (
        <Row>
          {items.map((value, index) => (
            <NonSortableCategory
              key={`item-${value.id}`}
              value={value}
              idx={index}
            />
          ))}
        </Row>
      );
    }
  };
  if (!data.categories.length) {
    return (
      <EmptyState
        title="You have no existing categories"
        buttonTitle="+ Add New Category"
        buttonLink={{
          pathname: "/category/add",
          state: { catLen: data.categories.length + 1 },
        }}
      />
    );
  }

  return (
    <Container className="container">
      <Table>
        <Head>
          <Row>
            <Col>Category</Col>
            <Col>Total Workouts</Col>
            <Col>Active</Col>
            <Col>
              <ADD_BUTTON
                to={{
                  pathname: "/category/add",
                  state: { catLen: data.categories.length + 1 },
                }}
              >
                <p>+ Add New Category</p>
              </ADD_BUTTON>
            </Col>
          </Row>
        </Head>
        <Body>
          <SortableList items={activeCategories} onSortEnd={onSortEnd} />
          <NonSortableList items={inActiveCategories} />
        </Body>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin: 1px 121px 1px 119px;
  font-family: ${theme.PRIMARY_FONT};
`;

export const Table = styled.table`
  padding: 23px 25px;
  text-align: left;
  display: grid;
  border-radius: 18px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 1fr);
`;

export const ADD_BUTTON = styled(Link)`
  margin-top: -100px;
  margin-left: 40px;
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
