import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import { BACK_BUTTON, SAVE_BUTTON, Switch, TEXT_INPUT, Err } from "../../UI";
import { DeletePromotion } from "./DeletePromotion";
import * as Yup from "yup";

const promotionSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  photo: Yup.string().required("please upload an image"),
  link: Yup.string()
    .required("Deeplink URL is required")
    .url("Please insert valid Url  ex) https://www.google.com "),
});

export const PromotionForm = (props) => {
  let history = useHistory();

  // const categoryId = props.cateogryId;
  // const workoutId = props.workoutId;
  const { promotionalItem, promotonId, promoLen } = props;
  // const { state, dispatch } = useContext(AppContext);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: promotionSchema,
    initialValues: {
      id: promotionalItem ? promotionalItem.id : "",
      title: promotionalItem ? promotionalItem.title : "",
      description: promotionalItem ? promotionalItem.description : "",
      photo: promotionalItem ? promotionalItem.photo : "",
      isActive: promotionalItem ? promotionalItem.isActive : false,
      link: promotionalItem ? promotionalItem.link : "",
    },

    onSubmit: async (values) => {
      console.log(props);

      if (promotonId) {
        promoMutation({
          variables: {
            data: {
              title: { set: values.title },
              description: { set: values.description },
              photo: { set: values.photo },
              isActive: { set: values.isActive },
              link: { set: values.link },
            },
            where: { id: promotionalItem.id },
          },
        });
      } else {
        promoMutation({
          variables: {
            data: {
              title: values.title,
              description: values.description,
              photo: values.photo,
              isActive: values.isActive,
              link: values.link,
              sortOrder: promoLen,
            },
          },
        });
      }
    },
  });
  // // gql mutation

  const [promoMutation, { loading }] = useMutation(props.promoMutation, {
    onCompleted(data) {
      if (!data) {
        console.log("error ? data not received ?");
        return;
      }
      history.push(`/promotions`);
    },
    onError({ message }) {
      console.log(message);

      return <div>{JSON.stringify(message)}</div>;
    },
  });

  const { errors, touched } = formik;

  return (
    <Container>
      <BACK_BUTTON to="/promotions">
        <FaChevronLeft fontSize={20} />
        <p>Back to Workout</p>
      </BACK_BUTTON>

      <MainForm onSubmit={formik.handleSubmit}>
        <Section className="first">
          <h2>Add New Featured Content</h2>

          <div className="flex-col">
            {formik.values.title ? <LabelRow>title</LabelRow> : null}

            <TEXT_INPUT
              type="text"
              width={310}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Featured Content Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
            {errors.title && touched.title ? (
              <ErrText>{errors.title}</ErrText>
            ) : null}
          </div>
          <div className="flex-col">
            {formik.values.description ? (
              <LabelRow>description</LabelRow>
            ) : null}

            <TEXT_INPUT
              type="text"
              width={310}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Featured Content Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {errors.description && touched.description ? (
              <ErrText>{errors.description}</ErrText>
            ) : null}
          </div>
          <div className="flex-col">
            {formik.values.link ? <LabelRow>deeplink</LabelRow> : null}

            <TEXT_INPUT
              type="text"
              width={310}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Link to Web URL / In-App Deeplink"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
            />
            {errors.link && touched.link ? (
              <ErrText>{errors.link}</ErrText>
            ) : null}
          </div>
          <div className="flex-row-switch">
            <p>Active </p>
            <Switch
              isOn={formik.values.isActive}
              onColor={theme.GREEN_COLOR}
              handleToggle={() =>
                formik.setFieldValue("isActive", !formik.values.isActive)
              }
              className={"add-new"}
            />
          </div>
        </Section>
        <Section className="last">
          <ImageUpload
            formik={formik}
            name="photo"
            header="Content Banner Image"
          />
          {errors.photo && touched.photo ? <Err>{errors.photo}</Err> : null}
        </Section>

        <SubmitSection>
          <FlexRow>
            <SAVE_BUTTON type="submit">Save Changes</SAVE_BUTTON>
            <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
              Cancel
            </SAVE_BUTTON>
          </FlexRow>
          {promotonId ? (
            <DeletePromotion text="Delete Promotion" promotionId={promotonId} />
          ) : null}
        </SubmitSection>
      </MainForm>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
  .flex-col {
    display: flex;
    flex-direction: column;
  }
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  padding-bottom: 50px;

  .flex-row {
    display: inline-flex;
    flex-direction: row;
    & > * {
      margin-right: 20px;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .flex-row-switch {
    display: flex;
    align-self: end;
    & > * {
      padding: 5px;
    }
`;

const Section = styled.section`
  display: inline-flex;
  flex-direction: column;
  margin: 36px 51px;
  min-width: 33%;
  .last {
    margin: 57px 51px;
  }
`;

const SubmitSection = styled.section`
  margin: auto;
  margin-top: 100px;
  margin-bottom: 157px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  & > * {
    margin: 26px;
  }
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;

const ErrText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.05px;
  color: ${theme.INPUT_BORDER_COLOR};
  margin-top: -10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-botom: 10px;
`;
