import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { isLoggedInVar } from "../../apollo/cache";
import { theme } from "../../utils/theme";

export const EmptyState = (props) => {
  const { title, buttonTitle, buttonLink, buttonAction } = props;
  return (
    <Container>
      <Title>{title}</Title>
      {buttonLink ? (
        <AppNav to={buttonLink}>
          <p>{buttonTitle}</p>
        </AppNav>
      ) : (
        <ADD_BUTTON onClick={buttonAction}>
          <p>{buttonTitle}</p>
        </ADD_BUTTON>
      )}
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: baseline;
  align-items: baseline;
  margin-top: 200px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: ${theme.PRIMARY_FONT};
`;

const Title = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  letter-spacing: -0.1px;
  padding: 6px 0;
  color: #ffffff;
  display: inline;
  margin: auto;
  margin-bottom: 41px;
`;
const HeaderNav = styled.nav`
  font-family: ${theme.PRIMARY_FONT};
`;

const AppNav = styled(Link)`
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  -webkit-letter-spacing: -0.1px;
  -moz-letter-spacing: -0.1px;
  -ms-letter-spacing: -0.1px;
  letter-spacing: -0.1px;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: auto;
  color: ${theme.WHITE_COLOR};
  p {
    font-size: 13px;
    height: 43px;
  }
`;

const ADD_BUTTON = styled.button`
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  margin: auto;

  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
