import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { TEXT_INPUT } from "../../UI";

export const ProMusicLinks = (props) => {
  const { musicLinks, formik } = props;
  const [musiclen, setMusicLen] = useState(musicLinks.length || 0);

  const getProMusics = () => {
    const arr: any = [];
    for (let i = 0; i < musiclen; i++) {
      arr.push(
        <div key={i} className="flex-row">
          <TEXT_INPUT
            type="text"
            width={800}
            margin={[20, 0]}
            padding={[8, 0]}
            placeholder="Spotify Links"
            name={`musicLinks[${i}]`}
            value={formik.values.musicLinks[i] || ""}
            onChange={formik.handleChange}
          />
          <IconContainer onClick={() => deleteMusic(i)}>
            <FaTrashAlt color="#eb506e" size={11} />
          </IconContainer>
        </div>
      );
    }
    return arr;
  };

  const addMusic = (e) => {
    e.preventDefault();
    setMusicLen(musiclen + 1);
  };

  const deleteMusic = (index) => {
    console.log(index);
    formik.setFieldValue(
      "musicLinks",
      formik.values.musicLinks.filter((el, idx) => idx !== index)
    );
    setMusicLen(musiclen - 1);
  };

  return (
    <div>
      {getProMusics()}
      <AddMusic onClick={addMusic}> + Add Another Playlist Link</AddMusic>
    </div>
  );
};

const AddMusic = styled.p`
  font-size: 12px;
  color: #eb506e;
  letter-spacing: -0.09px;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
