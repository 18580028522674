import { gql } from "@apollo/client";
import {
  WorkoutDetail,
  ProDetails,
  ProMin,
  Promotion,
  Firmware,
} from "../fragments";

export const UPLOAD_IMAGE = gql`
  mutation($file: Upload!) {
    uploadImage(file: $file) {
      url
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation($file: Upload!) {
    uploadDocument(file: $file) {
      url
    }
  }
`;
export const UPLOAD_VIDEO = gql`
  mutation($file: Upload!) {
    uploadVideo(file: $file) {
      url
    }
  }
`;

// category
export const CREATE_ONE_CATEGORY = gql`
  mutation createOneCategory($data: CategoryCreateInput!) {
    createOneCategory(data: $data) {
      title
      imageUrl
      isActive
    }
  }
`;

export const EDIT_ONE_CATEOGRY = gql`
  mutation updateOneCategory(
    $data: CategoryUpdateInput!
    $where: CategoryWhereUniqueInput!
  ) {
    updateOneCategory(data: $data, where: $where) {
      title
      imageUrl
      isActive
    }
  }
`;

export const DELETE_ONE_CATEOGRY = gql`
  mutation deleteOneCategory($where: CategoryWhereUniqueInput!) {
    deleteOneCategory(where: $where) {
      id
      title
      imageUrl
      isActive
    }
  }
`;

// updateCategoryOrder

export const UPDATE_CATEGORY_ORDERS = gql`
  mutation updateCategoryOrder($data: [String!]!) {
    updateCategoryOrder(categoryIds: $data)
  }
`;

export const UPDATE_PROMOTIONS_ORDERS = gql`
  mutation updatePromotionOrder($data: [String!]!) {
    updatePromotionOrder(promoIds: $data)
  }
`;

// workout

export const CREATE_ONE_WORKOUT = gql`
  mutation createOneWorkout($data: WorkoutCreateInput!) {
    createOneWorkout(data: $data) {
      ...Workout
    }
  }
  ${WorkoutDetail}
`;

export const UPDATE_ONE_WORKOUT = gql`
  mutation updateOneWorkout(
    $data: WorkoutUpdateInput!
    $where: WorkoutWhereUniqueInput!
  ) {
    updateOneWorkout(data: $data, where: $where) {
      ...Workout
    }
  }
  ${WorkoutDetail}
`;

export const UPDATE_MANY_WORKOUT = gql`
  mutation updateManyWorkout(
    $data: WorkoutUpdateManyMutationInput!
    $where: WorkoutWhereInput
  ) {
    updateManyWorkout(data: $data, where: $where) {
      count
    }
  }
`;

export const DELETE_ONE_WORKOUT = gql`
  mutation deleteOneWorkout(
    $where: WorkoutWhereUniqueInput!
    $exerciseWorkout: ExerciseWhereInput
  ) {
    deleteManyExercise(where: $exerciseWorkout) {
      count
    }
    deleteOneWorkout(where: $where) {
      id
      title
      imageUrl
      isActive
    }
  }
`;

// exercise

export const UPDATE_ONE_EXERCISE = gql`
  mutation updateOneExercise(
    $data: ExerciseUpdateInput!
    $where: ExerciseWhereUniqueInput!
  ) {
    updateOneExercise(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_ONE_EXERCISE = gql`
  mutation deleteOneExercise($where: ExerciseWhereUniqueInput!) {
    deleteOneExercise(where: $where) {
      id
    }
  }
`;

// pro

export const CREATE_ONE_PRO = gql`
  mutation createOnePro($data: ProCreateInput!) {
    createOnePro(data: $data) {
      ...Pro
    }
  }
  ${ProDetails}
`;

export const UPDATE_ONE_PRO = gql`
  mutation updateOnePro($data: ProUpdateInput!, $where: ProWhereUniqueInput!) {
    updateOnePro(data: $data, where: $where) {
      ...Pro
    }
  }
  ${ProDetails}
`;

export const DELETE_ONE_PRO = gql`
  mutation deleteOnePro($where: ProWhereUniqueInput!) {
    deleteOnePro(where: $where) {
      id
    }
  }
`;

export const CREATE_ONE_PROMOTION = gql`
  mutation createOnePromotionalItem($data: PromotionalItemCreateInput!) {
    createOnePromotionalItem(data: $data) {
      ...Promotion
    }
  }
  ${Promotion}
`;

export const UPDATE_ONE_PROMOTION = gql`
  mutation updateOnePromotionalItem(
    $data: PromotionalItemUpdateInput!
    $where: PromotionalItemWhereUniqueInput!
  ) {
    updateOnePromotionalItem(data: $data, where: $where) {
      ...Promotion
    }
  }
  ${Promotion}
`;

export const DELETE_ONE_PROMOTION = gql`
  mutation deleteOnePromotionalItem($where: PromotionalItemWhereUniqueInput!) {
    deleteOnePromotionalItem(where: $where) {
      id
    }
  }
`;

export const CREATE_ONE_FIRMWARE = gql`
  mutation createOneFirmwareContent($data: FirmwareContentCreateInput!) {
    createOneFirmwareContent(data: $data) {
      ...FirmwareContent
    }
  }
  ${Firmware}
`;

export const DELETE_ONE_FIRMWARE = gql`
  mutation deleteOneFirmwareContent($where: FirmwareContentWhereUniqueInput!) {
    deleteOneFirmwareContent(where: $where) {
      id
    }
  }
`;
export const UPDATE_ONE_FIRMWARE = gql`
  mutation updateOneFirmwareContent(
    $data: FirmwareContentUpdateInput!
    $where: FirmwareContentWhereUniqueInput!
  ) {
    updateOneFirmwareContent(data: $data, where: $where) {
      ...FirmwareContent
    }
  }
  ${Firmware}
`;
