import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UPLOAD_IMAGE } from "../../apollo/mutations";
import { Loading } from "../UI";

export const ImageUpload = (props) => {
  const formik = props.formik;

  const [uploaded, setUploadStatus] = useState(false);
  const [src, setSrc] = useState("");
  const [fallback, setFallback] = useState(false);
  const name = props.name ? props.name : "imageUrl";
  const header = props.header ? props.header : "Banner Image";

  let width = 275,
    height = 275;
  if (name === "bigImage") {
    width = 410;
    height = 295;
  }
  const uploadStatus = !!formik.values[name];

  useEffect(() => {
    setUploadStatus(uploadStatus);
    setSrc(formik.values[name]);
  }, [uploadStatus, props]);

  const [imageUploadMutation, { loading }] = useMutation(UPLOAD_IMAGE, {
    onCompleted({ uploadImage }) {
      if (!uploadImage) {
        return;
      }
      formik.setFieldValue(name, uploadImage.url);
      setSrc(uploadImage.url);
    },
    onError({ message }) {
      return <div>{JSON.stringify(message)}</div>;
    },
  });

  if (loading) {
    return (
      <ImageContainer width={width} height={height}>
        <Loading />
      </ImageContainer>
    );
  }

  const fileHandler = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    console.log(file);

    if (!file) {
      return (
        <ImageContainer width={width} height={height}>
          <Loading />
        </ImageContainer>
      );
    }
    imageUploadMutation({ variables: { file } });
    if (file) {
      setUploadStatus(true);
    } else {
      return (
        <ImageContainer width={width} height={height}>
          <Loading />
        </ImageContainer>
      );
    }
  };
  const uploadText = uploaded ? "Replace Image" : "Upload";
  const reloadSrc = (e) => {
    if (fallback) {
      e.target.src = src;
    } else {
      e.target.src = src;
      setFallback(true);
    }
  };

  return (
    <div>
      <h3>{header}</h3>
      <ImageContainer uploaded={uploaded} width={width} height={height}>
        <Image
          uploaded={uploaded}
          src={formik.values[name]}
          alt={"image"}
          onError={reloadSrc}
          width={width}
          height={height}
        />
        <UploadContainer className="image-details">
          <NewUploadText uploaded={uploaded}>Upload {header}</NewUploadText>
          <Label htmlFor={name}>
            <p>{uploadText}</p>
          </Label>
          <input
            id={name}
            type="file"
            accept="image/*"
            onChange={fileHandler}
            style={{ visibility: "hidden" }}
          />
        </UploadContainer>
      </ImageContainer>
    </div>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
  width?: number;
  height?: number;
}

const UploadContainer = styled.div<UploadedProps>``;

const NewUploadText = styled.p<UploadedProps>`
  display: ${(props) => (props.uploaded ? "none" : "block")};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #212121;
`;

const ImageContainer = styled.div<UploadedProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .image-details {
    display: ${(props) => (props.uploaded ? "none" : "flex")};
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
  }
  &:hover {
    color: #ffffff;
    .image-details {
      display: flex;
      position: absolute;
      z-index: 1;
      background: ${(props) =>
        props.uploaded ? "rgba(33, 33, 33, 0.5)" : "inherit"};
    }
    .image-details label {
      background: ${(props) => (props.uploaded ? "none" : "#212121")};
    }
  }
`;

const Image = styled.img<UploadedProps>`
  position: relative;
  width: ${(props) => (props.uploaded ? props.width : 0)}px;
  height: ${(props) => (props.uploaded ? props.height : 0)}px;
  display: ${(props) => (props.uploaded ? "inline" : "none")};
`;

const Label = styled.label`
  margin: 10px auto;
  border-radius: 23.8px;
  min-width: 129px;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: #ffffff;
  background: #212121;
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
