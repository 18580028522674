import React from "react";
import { FirmwareForm } from "./FirmwareForm";
import { UPDATE_ONE_FIRMWARE } from "../../../apollo/mutations";
import { GET_ONE_FIRMWARE } from "../../../apollo/queries";
import { useQuery } from "@apollo/client";
import { Loading } from "../../UI";

export const EditFirmware = (props) => {
  // formik
  const firmwareId = props.match.params.firmwareId;

  const { data, loading } = useQuery(GET_ONE_FIRMWARE, {
    variables: {
      data: { id: firmwareId },
    },
  });

  if (loading) return <Loading />;

  const firmware = data.firmwareContent;
  return (
    <FirmwareForm
      firmwareId={firmwareId}
      firmware={firmware}
      firmwareMutation={UPDATE_ONE_FIRMWARE}
      dateType="Add"
      fileType={firmware.type}
    />
  );
};
