import React from "react";
import { useHistory } from "react-router-dom";
import { SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import { ReactComponent as BurgerIcon } from "../../../images/burgerIcon.svg";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { theme } from "../../../utils/theme";
import { Col } from "../../UI";
import { DeletePromotion } from "./DeletePromotion";

export const SortablePromo = SortableElement(({ value, idx }) => {
  let history = useHistory();
  const { id, title, isActive, link, photo } = value;

  const handleEdit = () => {
    history.push(`/promotion/edit/${id}`);
  };

  const activeStatus = isActive ? "Active" : "Inactive";
  return (
    <>
      <Col>
        <SortIndex>{idx + 1}</SortIndex>
        <Burger />

        <MainImage src={photo} alt="pro image" />
        <p>{title}</p>
      </Col>
      <Col>
        <p> {activeStatus}</p>
      </Col>
      <Col>
        <p>{link}</p>
      </Col>
      <Col className="last-el">
        <div className="icon">
          <EditIcon onClick={() => handleEdit()} />
          <DeletePromotion promotionId={value.id} text={null} />
        </div>
      </Col>
    </>
  );
});

export const NonSortablePromo = ({ value, idx }) => {
  let history = useHistory();
  const { id, title, isActive, link, photo } = value;

  const handleEdit = () => {
    history.push(`/promotion/edit/${id}`);
  };

  const activeStatus = isActive ? "Active" : "Inactive";
  return (
    <>
      <Col>
        <NonSortIndex></NonSortIndex>
        <MainImage src={photo} alt="pro image" />
        <p>{title}</p>
      </Col>
      <Col>
        <p> {activeStatus}</p>
      </Col>
      <Col>
        <p>{link}</p>
      </Col>
      <Col className="last-el">
        <div className="icon">
          <EditIcon onClick={() => handleEdit()} />
          <DeletePromotion promotionId={value.id} text={null} />
        </div>
      </Col>
    </>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

export const Table = styled.table`
  text-align: left;
  display: grid;
  border-radius: 18px;
  padding: 23px 25px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);

  .last-el {
    display: flex;
    flex-direction: row;
    justify-self: right;
  }
  .add-margin {
    margin-top: -120px;
  }
`;

const Burger = styled(BurgerIcon)`
  margin: 12px;
`;

const SortIndex = styled.p`
  width: 25px;
  text-align: center;
  display: block;
`;

const NonSortIndex = styled.p`
  width: 73px;
  text-align: center;
  display: block;
`;
