import React from "react";
import { CREATE_ONE_CATEGORY } from "../../../apollo/mutations";
import { CategoryForm } from "./CategoryForm";

export const AddCategory = (props) => {
  // render

  const catLen = props.location.state.catLen;

  return <CategoryForm catMutation={CREATE_ONE_CATEGORY} catLen={catLen} />;
};
