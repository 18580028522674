import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GET_EXERCISES } from "../../../apollo/queries";
import { AppContext } from "../../../store";
import { Loading, SAVE_BUTTON, SwalError } from "../../UI";
import { DeleteExercise } from "./DeleteExercise";
import { Exercise } from "./Exercise";
import { theme } from "../../../utils/theme";

export const Exercises = (props) => {
  const { workoutId, videoLength, getExercises, formik } = props;
  const { dispatch, state } = useContext(AppContext);
  const [exerciseLength, setexerciseLength] = useState(0);
  const [exerciseList, setExercseList] = useState({});
  const [exerciseArr, setExercseArr] = useState<Array<object>>([]);

  // query
  const skip = !workoutId;
  const { loading: loading_exercises } = useQuery(GET_EXERCISES, {
    fetchPolicy: "network-only",
    skip: skip,
    onCompleted: ({ exercises }) => {
      console.log(exercises, workoutId);

      let object = {};
      for (const exercise of exercises) {
        const { id, name, start, end } = exercise;
        object[id] = { name, start, end, id, type: "Edit" };
      }
      setExercseList({ ...exerciseList, ...object });
      setexerciseLength(exercises.length);
      setExercseArr(Object.values(object));
    },
    variables: {
      where: {
        workoutId: { equals: workoutId },
      },
      orderBy: {
        start: "asc",
      },
    },
  });

  const deleteExercise = (exerciseId) => {
    if (formik.values.exerciseCheck === true) {
      formik.setFieldValue("exerciseCheck", false);
    }
    setexerciseLength(exerciseLength + 1);
    delete exerciseList[exerciseId];

    setExercseList({ ...exerciseList });
    const idx = exerciseArr.findIndex((el: any) => el.id === exerciseId);
    exerciseArr.splice(idx, 1);

    setExercseArr(exerciseArr);
  };

  const updateExercise = (exercise) => {
    setExercseList({ ...exerciseList, ...exercise });
  };

  const createListArray = () => {
    return exerciseArr.map(({ id, name, start, end, type }: any) => {
      return (
        <div key={id} className="flex-row">
          <Exercise
            index={id}
            name={name}
            start={start}
            end={end}
            exerciseId={id}
            updateParent={updateExercise}
            max={videoLength}
            type={type}
            videoDuration={videoLength}
          />
          <div>
            <DeleteExercise
              exerciseId={id}
              text={null}
              workoutId={id}
              type={type}
              updateParent={deleteExercise}
            />
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    setExercseList(exerciseList);
  }, []);

  const sortFunction = (data) => {
    console.log(exerciseList, exerciseArr.length, data.length);
    return data.sort(function (a, b) {
      return a.start - b.start;
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    const list = sortFunction(Object.values(exerciseList));
    if (list[0].start !== 0) {
      return SwalError("please update first exercise to start at 00:00");
    }
    if (list.length === 1) {
      const current = list[0];
      current.end = videoLength;
    }

    for (let i = 0; i < list.length - 1; i++) {
      const current = list[i];
      const next = list[i + 1];
      current.end = next.start - 1;

      if (current.start === next.start) {
        return SwalError(
          "duplicate start time found please check exercise and update field"
        );
      }
      exerciseList[current.id] = current;

      if (i + 1 === list.length - 1) {
        next.end = videoLength;
      }
    }
    setExercseArr(list);
    getExercises(list);
    formik.setFieldValue("exerciseCheck", true);
  };

  const addExercise = (e) => {
    e.preventDefault();
    if (videoLength === 0) {
      return SwalError("please upload video first to add exercises");
    }
    setexerciseLength(exerciseLength + 1);
    const newExercise: any = {
      name: "",
      start: 0,
      end: 0,
      id: exerciseLength,
      type: "New",
    };
    exerciseList[exerciseLength] = newExercise;
    setExercseList({ ...exerciseList });
    exerciseArr.push(newExercise);
    setExercseArr(exerciseArr);
  };

  if (loading_exercises) return <Loading />;

  return (
    <Container>
      <div>
        {exerciseArr.length > 0 && (
          <SAVE_BUTTON onClick={handleClick}>Apply</SAVE_BUTTON>
        )}
      </div>
      {createListArray()}
      <p className="add-new-workout" onClick={addExercise}>
        + Add Another Exercise
      </p>
    </Container>
  );
};

const Container = styled.section`
  button {
    font-family: ${theme.PRIMARY_FONT};
  }

  .add-new-workout {
    font-size: 12px;
    color: #eb506e;
    letter-spacing: -0.09px;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }
`;
