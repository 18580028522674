import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { isLoggedInVar } from "../../apollo/cache";
import { theme } from "../../utils/theme";

export const Header = () => {
  const logout = async () => {
    localStorage.clear();
    isLoggedInVar(false);
  };
  return (
    <Container>
      <Title>ALTER</Title>
      <HeaderNav>
        <StyledLink to="/categories"> Categories </StyledLink>
        <StyledLink to="/workouts/all"> Workouts </StyledLink>
        <StyledLink to="/pros">Alter Pros</StyledLink>
        <StyledLink to="/promotions">Promotional Contents</StyledLink>
        <StyledLink to="/firmwares">Firmware</StyledLink>
      </HeaderNav>
      <div>
        <StyledButton onClick={logout}>Logout</StyledButton>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 46px;
  vertical-align: baseline;
  margin: 20px 120px 61px 120px;
  align-items: baseline;
`;

const Title = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  padding: 6px 0;
  color: #ffffff;
`;
const HeaderNav = styled.nav`
  font-family: ${theme.PRIMARY_FONT};
`;

const activeClassName = "nav-item-active";

const StyledLink = styled(NavLink).attrs({ activeClassName })`
  padding: 20px;
  margin: auto;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding: 6px 0;
  margin: 0 20px;
  letter-spacing: -0.1px;
  text-decoration: none;
  &.${activeClassName} {
    border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  }
`;

const StyledButton = styled.button`
  padding: 20px;
  margin: auto;
  border: none;
  background: inherit;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding: 6px 0;
  margin: 0 20px;
  letter-spacing: -0.1px;
  text-decoration: none;
  &.${activeClassName} {
    border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
