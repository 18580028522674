import { ApolloProvider } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { client } from "./apollo";
import GlobalFonts from "./fonts/fonts";
import AppRouter from "./routers/AppRouter";
import { theme } from "./utils/theme";
import { ModalProvider } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";
function App() {
  return (
    <ApolloProvider client={client}>
      <Main>
        <ModalProvider rootComponent={TransitionGroup}>
          <GlobalFonts />
          <AppRouter />
        </ModalProvider>
      </Main>
    </ApolloProvider>
  );
}

const Main = styled.div`
  min-height: 100vh;
  fontfamily: ${theme.PRIMARY_FONT};
  background: ${theme.BACKGROUND_COLOR};
`;

export default App;
