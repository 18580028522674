import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { DELETE_ONE_EXERCISE } from "../../../apollo/mutations";
import { GET_EXERCISES } from "../../../apollo/queries";
import { ReactComponent as DeleteIcon } from "../../../images/ic-delete.svg";
import { AppContext } from "../../../store";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteExercise = ({
  exerciseId,
  text,
  workoutId,
  type,
  updateParent,
}) => {
  // local set

  const { dispatch, state } = useContext(AppContext);

  const [deleteExercise, { loading }] = useMutation(DELETE_ONE_EXERCISE, {
    onCompleted(data) {
      return;
    },
  });

  const handleDelete = () => {
    if (type === "Edit") {
      deleteExercise({
        variables: {
          where: { id: exerciseId },
        },
      });
    }
    updateParent(exerciseId);
  };

  if (loading) return <Loading />;

  // render

  if (!text) {
    return <DeleteIcon onClick={() => handleDelete()} />;
  }
  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>{text}</p>
    </DELETE_BUTTON>
  );
};
