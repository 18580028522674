const PRIMARY_COLOR = "#3e65ff"; // dark blue
const BLACK_COLOR = "#16151a";
const DARK_GREY_COLOR = "#7a7a7a";
const MEDIUM_GREY_COLOR = "#505050";
const WELTER_GREY_COLOR = "#efefef";
const LIGHT_GREY_COLOR = "#e7e7e7";
const ICON_GREY_COLOR = "#848386";
const OFF_WHITE_COLOR = "#f4f4f4";
const WHITE_COLOR = "#ffffff";
const GREEN_COLOR = "#68d900";
const ORANGE_COLOR = "#ffb338";
const RED_COLOR = "#ee021d";
const BACKGROUND_COLOR = "#212121";
const INPUT_BORDER_COLOR = "#eb506e";
const SUBMIT_COLOR = "#eb506e";
const CANCEL_COLOR = "#afafaf";
const PLACE_HOLDER_COLOR = "#505050";

const PRIMARY_FONT = "DMSans";
const SECONDARY_FONT = "serif";

export const theme = {
  PRIMARY_COLOR,
  BLACK_COLOR,
  WHITE_COLOR,
  RED_COLOR,
  LIGHT_GREY_COLOR,
  WELTER_GREY_COLOR,
  OFF_WHITE_COLOR,
  GREEN_COLOR,
  ORANGE_COLOR,
  DARK_GREY_COLOR,
  MEDIUM_GREY_COLOR,
  PRIMARY_FONT,
  SECONDARY_FONT,
  ICON_GREY_COLOR,
  BACKGROUND_COLOR,
  PLACE_HOLDER_COLOR,
  INPUT_BORDER_COLOR,
  SUBMIT_COLOR,
  CANCEL_COLOR,
};
