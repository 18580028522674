import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { Login } from "../Components/Pages/Login";
import { Categories } from "../Components/Pages/Categories/Categories";
import { AddCategory } from "../Components/Pages/Categories/AddCategory";
import { EditCategory } from "../Components/Pages/Categories/EditCategory";

import { Workouts } from "../Components/Pages/Workouts/Workouts";
import { AddWorkout } from "../Components/Pages/Workouts/AddWorkout";
import { EditWorkout } from "../Components/Pages/Workouts/EditWorkout";
import { SortableComponent } from "../Components/Pages/Workouts/Test";

import { Pros } from "../Components/Pages/Pros/Pros";
import { AddPro } from "../Components/Pages/Pros/AddPro";
import { EditPro } from "../Components/Pages/Pros/EditPro";

import { Promotions } from "../Components/Pages/Promotions/Promotions";
import { AddPromotion } from "../Components/Pages/Promotions/AddPromotion";
import { EditPromotion } from "../Components/Pages/Promotions/EditPromotion";

import { Firmwares } from "../Components/Pages/Firewares/Firmwares";
import { AddFirmware } from "../Components/Pages/Firewares/AddFirmware";
import { EditFirmware } from "../Components/Pages/Firewares/EditFirmware";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact component={Categories} />
        <PrivateRoute path="/categories" exact component={Categories} />
        <PrivateRoute path="/category/add" exact component={AddCategory} />
        <PrivateRoute
          path="/category/edit/:categoryId"
          exact
          component={EditCategory}
        />
        <PrivateRoute path="/workouts/:categoryId" exact component={Workouts} />
        <PrivateRoute
          path="/workout/:categoryId/add"
          exact
          component={AddWorkout}
        />
        <PrivateRoute
          path="/workout/:categoryId/edit/:workoutId"
          exact
          component={EditWorkout}
        />

        <PrivateRoute path="/pros" exact component={Pros} />
        <PrivateRoute path="/pro/add" exact component={AddPro} />
        <PrivateRoute path="/pro/edit/:proId" exact component={EditPro} />
        <PrivateRoute path="/test" exact component={SortableComponent} />

        <PrivateRoute path="/promotions" exact component={Promotions} />
        <PrivateRoute path="/promotion/add" exact component={AddPromotion} />
        <PrivateRoute
          path="/promotion/edit/:promotionId"
          exact
          component={EditPromotion}
        />

        <PrivateRoute path="/firmwares" exact component={Firmwares} />
        <PrivateRoute path="/firmware/add" exact component={AddFirmware} />
        <PrivateRoute
          path="/firmware/edit/:firmwareId"
          exact
          component={EditFirmware}
        />

        <PublicRoute path="/login" exact component={Login} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
