import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React from "react";
import styled from "styled-components";
import { client } from "../../apollo";
import { isLoggedInVar } from "../../apollo/cache";
import { theme } from "../../utils/theme";

interface MyFormikProps {
  username: string;
  password: string;
}

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      id
      sessionToken
    }
  }
`;

export const Login = () => {
  const [loginMutation, { loading, error }] = useMutation(LOGIN_USER, {
    onCompleted({ adminLogin }) {
      if (!adminLogin) {
        return;
      }
      localStorage.setItem("token", adminLogin.sessionToken as string);
      localStorage.setItem("user_id", adminLogin.id as string);
      isLoggedInVar(true);
    },
    fetchPolicy: "no-cache",
    onError({ message }) {
      console.log("Error logging in: ", message);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      loginMutation({
        variables: { email: values.email, password: values.password },
      });
    },
  });

  const error_message = error ? "Invalid Username / Password" : "";

  if (loading) return <div>loading...</div>;
  return (
    <LoginBackGround>
      <Title>ALTER</Title>
      <LoginForm onSubmit={formik.handleSubmit}>
        <AppErrorText>{error_message}</AppErrorText>
        <FlexCol>
          {formik.values.email ? <LabelRow>Email</LabelRow> : null}
          <LoginInput
            placeholder="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FlexCol>
        <FlexCol>
          {formik.values.password ? <LabelRow>Password</LabelRow> : null}
          <LoginInput
            placeholder="Password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </FlexCol>
        <AMan href="www.google.com">Forgot Password ? </AMan>

        <SAVE_BUTTON type="submit">Login</SAVE_BUTTON>
      </LoginForm>
    </LoginBackGround>
  );
};

const Title = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-family: DMSans;
  font-size: 37px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  color: white;
`;
const LoginInput = styled.input`
  background: none;
  border: none;
  margin: 38px 10px;
  padding: 10px 5px;
  width: 345px;
  align-self: center;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  color: ${theme.WHITE_COLOR};
  borderbottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    background: ${theme.BACKGROUND_COLOR};
    outline: none;
  }
`;

const LoginBackGround = styled.div`
  color: ${theme.WHITE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 37px;
  font-weight: bold;
  text-align: center;
`;

const AMan = styled.a`
  color: ${theme.WHITE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  text-align: center;
  color: #e7e7e7;
  text-decoration: none;
  margin: auto;
`;

const SAVE_BUTTON = styled.button`
  display: block;
  align-items: center;
  border: none;
  width: 186px;
  line-height: 43px;
  color: #ffffff;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-decoration: none;
  margin-top: 55px;
  align-self: center;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

export const AppErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.09px;
  text-align: center;
  color: ${theme.INPUT_BORDER_COLOR};
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: 34px;
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 30px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
  margin-left: 12px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: auto;
`;
