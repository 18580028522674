import { useQuery } from "@apollo/client";
import React from "react";
import { CREATE_ONE_WORKOUT } from "../../../apollo/mutations";
import { GET_PROS } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { WorkoutForm } from "./WorkoutForm";

export const AddWorkout = (props) => {
  const catId = props.match.params.categoryId;

  return (
    <WorkoutForm
      cateogryId={catId}
      workoutMutation={CREATE_ONE_WORKOUT}
      dateType="Add"
      workout={null}
      exerciseCheck={false}
    />
  );
};
