import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { UPDATE_ONE_PROMOTION } from "../../../apollo/mutations";
import { GET_ONE_PROMOTION } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { PromotionForm } from "./PromotionForm";

export const EditPromotion = (props) => {
  let history = useHistory();
  const promotionId = props.match.params.promotionId;

  const { loading, error, data } = useQuery(GET_ONE_PROMOTION, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        id: promotionId,
      },
    },
  });

  if (loading) return <Loading />;
  console.log(data);

  return (
    <PromotionForm
      promotonId={promotionId}
      promoMutation={UPDATE_ONE_PROMOTION}
      dateType="Edit"
      promotionalItem={data.promotionalItem}
    />
  );
};
