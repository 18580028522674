import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_WORKOUT } from "../../../apollo/mutations";
import { ReactComponent as DeleteIcon } from "../../../images/ic-delete.svg";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteWorkout = ({ categoryId, workoutId, text }) => {
  // local set

  let history = useHistory();

  const [deleteWorkout, { loading }] = useMutation(DELETE_ONE_WORKOUT, {
    onCompleted(data) {
      console.log(categoryId);
      history.push(`/workouts/${categoryId}`);
    },
  });

  const handleDelete = () => {
    deleteWorkout({
      variables: {
        where: { id: workoutId },
        exerciseWorkout: {
          workoutId: { equals: workoutId },
        },
      },
    });
  };

  if (loading) return <Loading />;

  // render

  if (!text) {
    return <DeleteIcon onClick={handleDelete} />;
  }
  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>{text}</p>
    </DELETE_BUTTON>
  );
};
