import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GET_ALL_FIRMWARE } from "../../../apollo/queries";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { theme } from "../../../utils/theme";
import { useModal } from "react-modal-hook";

import { EmptyState } from "../../Segments/EmptyState";
// import Switch from "../UI";
import { Body, Col, Head, Loading, Row } from "../../UI";
import { FirmwareModal } from "./FirmwareModal";

const Firmware = (props) => {
  const { id, isActive, type, version, createdAt, fileUrl } = props;
  const history = useHistory();
  const handleEdit = () => {
    history.push(`/firmware/edit/${id}`);
  };
  const dateFormat = new Date(createdAt).toLocaleDateString("en-US");
  return (
    <Row>
      <Col>
        <p>{type}</p>
      </Col>
      <Col>
        <p> {version}</p>
      </Col>
      <Col>
        <p>{dateFormat}</p>
      </Col>
      <Col>
        <p>{isActive ? "Active" : "Inactive"}</p>
      </Col>
      <Col className="last-el">
        <DownloadLink href={fileUrl} target="_blank" download>
          <p>Download</p>
        </DownloadLink>

        <div className="icon">
          <EditIcon onClick={() => handleEdit()} />
        </div>
      </Col>
    </Row>
  );
};
export const Firmwares = (props) => {
  let history = useHistory();

  const [firmwares, setFirmwares] = useState([]);

  const { loading, error, data } = useQuery(GET_ALL_FIRMWARE, {
    fetchPolicy: "network-only",
    onCompleted: ({ firmwareContents }) => {
      setFirmwares(firmwareContents);
    },
  });
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <FirmwareModal
      open={open}
      onExited={onExited}
      onClose={hideModal}
      history={history}
    />
  ));

  // UPDATE_PROMOTIONS_ORDERS
  if (loading) return <Loading />;
  if (error) return `Error! ${error}`;

  if (!data.firmwareContents.length) {
    return (
      <EmptyState
        title="You have no existing Firmware"
        buttonTitle="+ Add New Firmware"
        buttonAction={showModal}
      />
    );
  }
  const view = data.firmwareContents.map((el) => {
    return (
      <Firmware
        id={el.id}
        key={el.id}
        version={el.version}
        type={el.type}
        fileUrl={el.fileUrl}
        createdAt={el.createdAt}
        isActive={el.isActive}
      />
    );
  });

  return (
    <Container className="container">
      <Table>
        <Head>
          <Row>
            <Col>
              <p>Type</p>
            </Col>
            <Col>
              <p>Version</p>
            </Col>
            <Col>
              <p>Upload Date</p>
            </Col>
            <Col>
              <p>Status</p>
            </Col>

            <Col className="last-el">
              <ADD_BUTTON onClick={showModal}>
                <p>+ Add New Firmware</p>
              </ADD_BUTTON>
            </Col>
          </Row>
        </Head>
        <Body>{view}</Body>
      </Table>
    </Container>
  );
};
const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

export const Table = styled.table`
  text-align: left;
  display: grid;
  border-radius: 18px;
  padding: 23px 25px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 1.5fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);

  .last-el {
    display: flex;
    flex-direction: row;
    justify-self: right;
  }
  .add-margin {
    margin-top: -120px;
  }
`;

const ADD_BUTTON = styled.button`
  margin-top: -105px;
  postion: absolute;
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const DownloadLink = styled.a`
  width: 128px;
  height: 30px;
  border-radius: 15px;
  background-color: #e7e7e7;
  text-decoration: none;
  margin-right: 30px;
  p {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    text-align: center;
    color: #000000;
    margin: auto;
  }
`;
