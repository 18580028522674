import React, { createContext, useContext, useReducer, Dispatch } from "react";
import { omit } from "lodash";

const initialState = {
  newExercises: {},
  existingExercises: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "addNew":
      return {
        ...state,
        newExercises: { ...state.newExercises, [action.key]: action.payload },
        sorted: false,
      };
    case "editNew":
      return {
        ...state,
        newExercises: { ...state.newExercises, [action.key]: action.payload },
        sorted: false,
      };
    case "fetch":
      return {
        ...state,
        existingExercises: action.payload,
      };
    case "edit":
      return {
        ...state,
        existingExercises: {
          ...state.existingExercises,
          [action.key]: action.payload,
        },
        sorted: false,
      };
    case "deleteNew":
      return {
        ...state,
        newExercises: omit(state.newExercises, action.key),
      };
    case "deleteEdit":
      return {
        ...state,
        existingExercises: omit(state.existingExercises, action.key),
      };
    case "addAll":
      return {
        ...state,
        newExercises: action.payload.new,
        existingExercises: action.payload.exist,
      };

    case "clear":
      return { ...initialState };
    default:
      return initialState;
  }
};

type InitialState = {
  newExercises: object;
  existingExercises: object;
  allExercises: any;
  sorted: boolean;
};

interface IContextProps {
  state: InitialState;
  dispatch: ({
    type,
    payload,
    key,
  }: {
    type: string;
    key?: any;
    payload?: any;
  }) => void;
}
const AppContext = React.createContext({} as IContextProps);

const AppProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
export { AppProvider, AppContext };
