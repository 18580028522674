import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React from "react";
import {
  FaChevronLeft,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { ReactComponent as Instagram } from "../../../images/instagram.svg";
import { ReactComponent as Spotify } from "../../../images/spotify.svg";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import { VideoUpload } from "../../Segments/VideoUpload";
import {
  AnimatedMulti,
  BACK_BUTTON,
  Err,
  SAVE_BUTTON,
  Switch,
  TEXTAREA,
  TEXT_INPUT,
} from "../../UI";
import { ProMusicLinks } from "../ProMusics/ProMusics";
import { DeletePro } from "./DeletePro";

export const ProForm = (props) => {
  let history = useHistory();

  // const categoryId = props.cateogryId;
  // const workoutId = props.workoutId;
  const { pro, proId } = props;
  // const { state, dispatch } = useContext(AppContext);

  /**
   * Exception row for now
    introVideo: Yup.string().required("introVideo is required!"),
    introDescription: Yup.string().required("introDescription is required!"),
    introTitle: Yup.string().required("introTitle is required!"),
    twitter: Yup.string().required("twitter is required!"),
    facebook: Yup.string().required("facebook is required!"),
   */

  const proSchema = Yup.object().shape({
    aboutMe: Yup.string().required("Pro Bio is required!"),
    email: Yup.string().required("email is required!"),
    name: Yup.string().required("Pro name is required!"),
    instagram: Yup.string().required("instagram is required!"),
    bigImage: Yup.string().required("Banner Image is required!"),
    profileImage: Yup.string().required("Profile Image is required!"),
    quote: Yup.string().required("Pro Quote is required!"),
    specialties: Yup.string().required("Specialties is required!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: proSchema,
    initialValues: {
      id: pro ? pro.id : "",
      name: pro ? pro.name : "",
      aboutMe: pro ? pro.aboutMe || "" : "",
      isActive: pro ? pro.isActive : false,
      email: pro ? pro.email : "",
      bigImage: pro ? pro.bigImage : "",
      profileImage: pro ? pro.profileImage : "",
      quote: pro ? pro.quote : "",
      introVideo: pro ? pro.introVideo : "",
      introDescription: pro ? pro.introDescription : "",
      introTitle: pro ? pro.introTitle : "",
      specialties: pro ? pro.specialties : [],
      facebook: pro ? pro.facebook || "" : "",
      instagram: pro ? pro.instagram || "" : "",
      twitter: pro ? pro.twitter || "" : "",
      musicLinks: pro ? pro.musicLinks : [],
      length: 0,
    },

    onSubmit: async (values) => {
      if (proId) {
        await proMutation({
          variables: {
            data: {
              name: { set: values.name },
              aboutMe: { set: values.aboutMe },
              isActive: { set: values.isActive },
              email: { set: values.email },
              instagram: { set: values.instagram },
              twitter: { set: values.twitter },
              facebook: { set: values.facebook },
              bigImage: { set: values.bigImage },
              profileImage: { set: values.profileImage },
              quote: { set: values.quote },
              introVideo: { set: values.introVideo },
              introDescription: { set: values.introDescription },
              introTitle: { set: values.introTitle },
              specialties: { set: values.specialties },
              musicLinks: { set: values.musicLinks },
            },
            where: { id: proId },
          },
        });
      } else {
        await proMutation({
          variables: {
            data: {
              name: values.name,
              isActive: values.isActive,
              email: values.email,
              instagram: values.instagram,
              twitter: values.twitter,
              facebook: values.facebook,
              bigImage: values.bigImage,
              profileImage: values.profileImage,
              quote: values.quote,
              introVideo: values.introVideo,
              introDescription: values.introDescription,
              introTitle: values.introTitle,
              specialties: { set: values.specialties },
              musicLinks: { set: values.musicLinks },
            },
          },
        });
      }
    },
  });

  // // gql mutation
  const [proMutation, { loading }] = useMutation(props.proMutation, {
    onCompleted(data) {
      if (!data) {
        return;
      }
      history.push(`/pros`);
    },
    onError({ message }) {
      return <div>{JSON.stringify(message)}</div>;
    },
  });

  const { errors, touched } = formik;
  return (
    <Container>
      <BACK_BUTTON to="/pros">
        <FaChevronLeft fontSize={20} />
        <p>Back to Workout</p>
      </BACK_BUTTON>

      <MainForm onSubmit={formik.handleSubmit}>
        <FirstSection>
          <div>Add New Alter Pro</div>
          <div className="flex-row">
            {formik.values.name ? <LabelRow>Alter Pro Name</LabelRow> : null}
            <TEXT_INPUT
              type="text"
              width={310}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Alter Pro Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <div className="flex-row-switch">
              <p>Active </p>
              <Switch
                isOn={formik.values.isActive}
                onColor={theme.GREEN_COLOR}
                handleToggle={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
                className={"add-new"}
              />
            </div>
          </div>
          {errors.name && touched.name ? <Err>{errors.name}</Err> : null}
        </FirstSection>

        <InputSection>
          <div className="flex-row">
            {formik.values.email ? <LabelRow>Email</LabelRow> : null}

            <TEXT_INPUT
              type="text"
              width={310}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Email"
              name="email"
              value={formik.values.email || ""}
              onChange={formik.handleChange}
            />
          </div>
          {errors.email && touched.email ? <Err>{errors.email}</Err> : null}
        </InputSection>

        <SpecialtySection>
          {formik.values.specialties.length > 0 ? (
            <LabelRow>Specialties</LabelRow>
          ) : null}

          <AnimatedMulti
            fieldname="specialties"
            defaultField={formik.values.specialties}
            formik={formik}
          />
        </SpecialtySection>

        <InputSection>
          <div className="flex-row">
            {formik.values.aboutMe ? <LabelRow>Bio</LabelRow> : null}

            <TEXTAREA
              width={800}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Bio"
              name="aboutMe"
              value={formik.values.aboutMe}
              onChange={formik.handleChange}
            />
          </div>
          {errors.aboutMe && touched.aboutMe ? (
            <Err>{errors.aboutMe}</Err>
          ) : null}
        </InputSection>

        <InputSection>
          <div className="flex-row">
            {formik.values.quote ? <LabelRow>Quote</LabelRow> : null}

            <TEXTAREA
              width={800}
              margin={[20, 0]}
              padding={[8, 0]}
              placeholder="Quote"
              name="quote"
              value={formik.values.quote}
              onChange={formik.handleChange}
            />
          </div>
          {errors.quote && touched.quote ? <Err>{errors.quote}</Err> : null}
        </InputSection>

        <UploadSection>
          <div className="flex-row">
            <div>
              <ImageUpload
                formik={formik}
                header="Profile Avatar"
                name="profileImage"
              />
            </div>
            <div>
              <ImageUpload
                formik={formik}
                header="Banner Image"
                name="bigImage"
              />
            </div>
          </div>
        </UploadSection>
        <InputSection>
          <div className="flex-col">
            <p>Social Media Link</p>

            {formik.values.facebook ? <LabelCol>Facebook</LabelCol> : null}

            <FlexRowCenter>
              <FaFacebookSquare color="#475993" size="21" />

              <TEXT_INPUT
                type="text"
                width={310}
                margin={[20, 0]}
                padding={[8, 0]}
                placeholder="Facebook"
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
              />
            </FlexRowCenter>

            {formik.values.instagram ? <LabelCol>Instagram</LabelCol> : null}

            <FlexRowCenter>
              <Instagram />

              <TEXT_INPUT
                type="text"
                width={310}
                margin={[20, 0]}
                padding={[8, 0]}
                placeholder="Instagram"
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
              />
            </FlexRowCenter>

            {formik.values.twitter ? <LabelCol>Twitter</LabelCol> : null}
            <FlexRowCenter>
              <FaTwitterSquare color="#55acee" size="21" />

              <TEXT_INPUT
                type="text"
                width={310}
                margin={[20, 0]}
                padding={[8, 0]}
                placeholder="Twitter"
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
              />
            </FlexRowCenter>
          </div>
        </InputSection>

        <InputSection>
          <div className="flex-col">
            <FlexRowCenter>
              <p>Spotify Playlist Links</p>
              <Spotify />
            </FlexRowCenter>

            <ProMusicLinks
              formik={formik}
              musicLinks={formik.values.musicLinks}
            />
          </div>
        </InputSection>

        <UploadSection>
          <div className="flex-col">
            <div>
              <VideoUpload
                formik={formik}
                header="introVideo"
                videoTitle="Welcome Video"
              />
            </div>
          </div>
        </UploadSection>
        <InputSection>
          {formik.values.introDescription ? (
            <LabelRow>Welcome Video Description</LabelRow>
          ) : null}

          <TEXTAREA
            width={800}
            margin={[20, 0]}
            padding={[8, 0]}
            placeholder="Welcome Video Description"
            name="introDescription"
            value={formik.values.introDescription}
            onChange={formik.handleChange}
          />
        </InputSection>
        <SubmitSection>
          <div className="flex-col-submit">
            <FlexRow>
              <SAVE_BUTTON type="submit">Save Changes</SAVE_BUTTON>
              <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
                Cancel
              </SAVE_BUTTON>
            </FlexRow>
            {proId ? <DeletePro text="Delete Alter Pro" proId={proId} /> : null}
          </div>
        </SubmitSection>
      </MainForm>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  display: grid;
  grid-gap: 10px;

  & > section {
    grid-column: 1 / span 6;
    margin: 0 51px;
  }

  section:first-child {
    margin: 0px 51px;
    margin-top: 62px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    & > * {
      margin-right: 20px;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-col-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex-row-switch {
    display: flex;
    align-self: center;
    & > * {
      padding: 5px;
    }
  }
`;

const FirstSection = styled.section``;
const InputSection = styled.section``;
const SpecialtySection = styled.section``;

const UploadSection = styled.section``;

const SubmitSection = styled.section`
  margin: auto;
  margin-top: 157px;
  margin-bottom: 157px;
  text-align: center;
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;

const LabelCol = styled.label`
  margin-bottom: -20px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
  margin-left: 35px;
`;

const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  & > * {
    padding: 5px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin: 26px;
  }
`;
