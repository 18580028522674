import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../..//utils/theme";
import { GET_ALL_PROS } from "../../../apollo/queries";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { EmptyState } from "../../Segments/EmptyState";
// import Switch from "../UI";
import { ADD_BUTTON, Body, Col, Head, Loading, Row } from "../../UI";
import { DeletePro } from "./DeletePro";

interface MyFormikProps {
  email: string;
  password: string;
}

const Pro = ({ id, imageUrl, email, total, isActive, name, refKey }) => {
  let history = useHistory();

  const handleEdit = () => {
    history.push(`/pro/edit/${id}`);
  };

  const activeStatus = isActive ? "Active" : "Inactive";
  return (
    <Row key={refKey}>
      <Col>
        <MainImage src={imageUrl} alt="pro image" />
        <p>{name}</p>
      </Col>
      <Col>
        <p> {total}</p>
      </Col>
      <Col>
        <p>{email}</p>
      </Col>
      <Col>
        <p> {activeStatus} </p>
      </Col>
      <Col className="last-el">
        <div className="icon">
          <EditIcon onClick={() => handleEdit()} />
          <DeletePro proId={id} text={null} />
        </div>
      </Col>
    </Row>
  );
};

export const Pros = (props) => {
  const { loading, error, data } = useQuery(GET_ALL_PROS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error}`;

  const view = data.pros.map((el, i) => {
    console.log(el.name);

    return (
      <Pro
        key={i}
        id={el.id}
        refKey={i}
        imageUrl={el.profileImage}
        email={el.email}
        name={el.name}
        isActive={el.isActive}
        total={el.WorkOuts.length}
      />
    );
  });

  if (!data.pros.length) {
    return (
      <EmptyState
        title="You have no existing Alter Pros"
        buttonTitle="+ Add New Alter Pro"
        buttonLink={`/pro/add`}
      />
    );
  }

  return (
    <Container className="container">
      <Table>
        <Head>
          <Row>
            <Col>
              <p>Alter Pro</p>
            </Col>
            <Col>
              <p>Workouts</p>
            </Col>
            <Col>
              <p>Email</p>
            </Col>
            <Col>
              <p>Active</p>
            </Col>
            <Col className="last-el">
              <ADD_BUTTON to={`/pro/add`} className="add-margin">
                <p>+ Add New Alter Pro</p>
              </ADD_BUTTON>
            </Col>
          </Row>
        </Head>
        <Body>{view}</Body>
      </Table>
    </Container>
  );
};
const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

export const Table = styled.table`
  text-align: left;
  display: grid;
  border-radius: 18px;
  padding: 23px 25px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);

  .last-el {
    display: flex;
    flex-direction: row;
    justify-self: right;
  }
  .add-margin {
    margin-top: -120px;
  }
`;
