import React from "react";
import { PromotionForm } from "./PromotionForm";
import { CREATE_ONE_PROMOTION } from "../../../apollo/mutations";

export const AddPromotion = (props) => {
  // formik

  const promoLen = props.location.state.promoLen;

  return (
    <PromotionForm
      promotonId={null}
      promoMutation={CREATE_ONE_PROMOTION}
      dateType="Add"
      promotionalItem={null}
      promoLen={promoLen}
    />
  );
};
