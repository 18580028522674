import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { UPLOAD_DOCUMENT } from "../../apollo/mutations";
import { ReactComponent as APkFile } from "../../images/apk.svg";
import { ReactComponent as Success } from "../../images/success.svg";
import { ReactComponent as ZipFile } from "../../images/zip.svg";
import { Loading } from "../UI";
export const FileUpload = ({ formik, fileType }) => {
  const [uploaded, setUploadStatus] = useState(!!formik.values.fileUrl);
  const [src, setSrc] = useState(formik.values.fileUrl);
  const name = "fileUpload";
  const header = fileType === "APK" ? ".apk file" : ".zip file";

  const allowFiles = fileType === "APK" ? ".apk" : ".zip,.rar,.7zip";
  let width = 275,
    height = 275;

  const uploadStatus = !!formik.values[name];

  const [fileUploadMutation, { loading }] = useMutation(UPLOAD_DOCUMENT, {
    onCompleted({ uploadDocument }) {
      formik.setFieldValue("fileUrl", uploadDocument.url);
      setSrc(uploadDocument.url);

      console.log("hit here", uploadDocument.url);

      setSrc(uploadDocument.url);
    },
    onError({ message }) {
      return <div>{JSON.stringify(message)}</div>;
    },
  });

  if (loading) {
    return <Loading />;
  }

  const fileHandler = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (file) {
      setUploadStatus(true);
      fileUploadMutation({ variables: { file } });
    } else {
      return <Loading />;
    }
  };
  const uploadText = uploaded ? `Replace ${fileType}` : "Upload";

  return (
    <div>
      <h3>{header}</h3>
      <ImageContainer uploaded={uploaded} width={width} height={height}>
        <Uploaded uploaded={uploaded} width={width} height={height}>
          <IconContainer>
            <Success />
          </IconContainer>
        </Uploaded>
        <UploadContainer className="image-details">
          <NewUploadText uploaded={uploaded}>
            {fileType === "APK" ? <APkFile /> : <ZipFile />}
          </NewUploadText>
          <Label htmlFor={name}>
            <p>{uploadText}</p>
          </Label>
          <input
            id={name}
            type="file"
            accept={allowFiles}
            onChange={fileHandler}
            style={{ visibility: "hidden" }}
          />
        </UploadContainer>
      </ImageContainer>
    </div>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
  width?: number;
  height?: number;
}

const UploadContainer = styled.div<UploadedProps>``;

const NewUploadText = styled.p<UploadedProps>`
  display: ${(props) => (props.uploaded ? "none" : "block")};
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #212121;
`;

const ImageContainer = styled.div<UploadedProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .image-details {
    display: ${(props) => (props.uploaded ? "none" : "flex")};
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
  }
  &:hover {
    color: #ffffff;
    .image-details {
      display: flex;
      position: absolute;
      z-index: 1;
      background: ${(props) =>
        props.uploaded ? "rgba(33, 33, 33, 0.5)" : "inherit"};
    }
    .image-details label {
      background: ${(props) => (props.uploaded ? "none" : "#212121")};
    }
  }
`;

const Uploaded = styled.div<UploadedProps>`
  position: relative;
  width: ${(props) => (props.uploaded ? props.width : 0)}px;
  height: ${(props) => (props.uploaded ? props.height : 0)}px;
  display: ${(props) => (props.uploaded ? "flex" : "none")};
`;

const Label = styled.label`
  margin: 10px auto;
  border-radius: 23.8px;
  min-width: 129px;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: #ffffff;
  background: #212121;
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;
