import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { theme } from "../../../utils/theme";
import { ImageUpload } from "../../Segments/ImageUpload";
import {
  BACK_BUTTON,
  Loading,
  SAVE_BUTTON,
  Switch,
  TEXT_INPUT,
} from "../../UI";
import { DeleteCategory } from "./DeleteCategory";
import { Err } from "../../UI";

const categorySchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  isActive: Yup.boolean(),
  imageUrl: Yup.string().required("Please upload image!"),
});

export const CategoryForm = (props) => {
  const [uploaded, setUploadStatus] = useState(false);

  let history = useHistory();

  const { category, categoryId, catMutation, catLen } = props;
  // formik

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: category ? category.title : "",
      isActive: category ? category.isActive : false,
      description: category ? category.description : "test",
      imageUrl: category ? category.imageUrl : "",
    },
    validationSchema: categorySchema,
    onSubmit: (values) => {
      if (!categoryId) {
        mutation({ variables: { data: { ...values, sortOrder: catLen } } });
      } else {
        mutation({
          variables: {
            data: {
              title: { set: values.title },
              imageUrl: { set: values.imageUrl },
              isActive: { set: values.isActive },
            },
            where: { id: categoryId },
          },
        });
      }
    },
  });

  const [mutation, { loading }] = useMutation(catMutation, {
    onCompleted(data) {
      history.push(`/categories`);
    },
  });

  // custom variable

  const uploadText = uploaded ? "Replace Banner Image" : "Upload";

  if (loading) {
    return <Loading />;
  }
  if (formik.errors) {
    console.log(formik.errors);
  }
  // render
  const { errors, touched } = formik;

  return (
    <Container>
      <BACK_BUTTON to="/categories">
        <FaChevronLeft fontSize={20} />
        <p>Back to Categories</p>
      </BACK_BUTTON>

      <MainForm onSubmit={formik.handleSubmit}>
        <FirstSection>
          <div>Add New Category</div>
          {formik.values.title ? <LabelRow>Title</LabelRow> : null}
          <TEXT_INPUT
            type="text"
            width={238}
            margin={[20, 0]}
            padding={[8, 0]}
            placeholder="Category Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
          {errors.title && touched.title ? <Err>{errors.title}</Err> : null}
          <div>
            <p>Active </p>
            <Switch
              isOn={formik.values.isActive}
              onColor={theme.GREEN_COLOR}
              handleToggle={() =>
                formik.setFieldValue("isActive", !formik.values.isActive)
              }
              className={"add-new"}
            />
          </div>
        </FirstSection>
        <SecondSection>
          <ImageUpload formik={formik} />
          {errors.imageUrl && touched.imageUrl ? (
            <Err>{errors.imageUrl}</Err>
          ) : null}
        </SecondSection>
        <LastSection>
          <FlexRow>
            <SAVE_BUTTON type="submit">Save</SAVE_BUTTON>

            <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
              Cancel
            </SAVE_BUTTON>
          </FlexRow>
          {categoryId ? <DeleteCategory categoryId={categoryId} /> : null}
        </LastSection>
      </MainForm>
    </Container>
  );
};

export interface UploadedProps {
  uploaded?: boolean;
  image?: boolean;
}

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  display: grid;
  grid-gap: 10px;
`;

const FirstSection = styled.section`
  margin: 36px 51px;
  grid-column: 1 / span 2;
  grid-row: 1;
`;
const SecondSection = styled.section`
  margin-top: 57px;
  grid-column: 3 / span 3;
  grid-row: 1;
`;

const LastSection = styled.section`
  margin: auto;
  margin-top: 157px;
  margin-bottom: 157px;
  grid-column: 1 / span 5;
  grid-row: 2;
  text-align: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin: 26px;
  }
`;

const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;
