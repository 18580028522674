import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_CATEOGRY } from "../../../apollo/mutations";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeleteCategory = (props) => {
  // local set
  const categoryId = props.categoryId;
  let history = useHistory();

  const [deleteOneCateogry, { loading }] = useMutation(DELETE_ONE_CATEOGRY, {
    onCompleted(data) {
      history.push(`/categories`);
    },
  });

  const handleDelete = () => {
    deleteOneCateogry({
      variables: {
        where: { id: categoryId },
      },
    });
  };

  if (loading) return <Loading />;

  // render

  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>Delete Category</p>
    </DELETE_BUTTON>
  );
};
