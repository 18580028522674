import { useQuery } from "@apollo/client";
import React from "react";
import { UPDATE_ONE_WORKOUT } from "../../../apollo/mutations";
import { GET_WORKOUT } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { WorkoutForm } from "./WorkoutForm";

export const EditWorkout = (props) => {
  const catId = props.match.params.categoryId;
  const workoutId = props.match.params.workoutId;

  const { loading, error, data } = useQuery(GET_WORKOUT, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        id: workoutId,
      },
    },
  });

  if (loading) return <Loading />;
  // event handler

  return (
    <WorkoutForm
      workout={data.workout}
      cateogryId={catId}
      workoutId={workoutId}
      workoutMutation={UPDATE_ONE_WORKOUT}
      exerciseCheck={true}
      dateType="Edit"
    />
  );
};
