import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import { theme } from "../../..//utils/theme";
import { GET_WORKOUTS } from "../../../apollo/queries";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { EmptyState } from "../../Segments/EmptyState";
// import Switch from "../UI";
import { Body, Col, Head, Loading, Row, Selector, TEXT_INPUT } from "../../UI";
import { DeleteWorkout } from "./DeleteWorkout";

interface MyFormikProps {
  email: string;
  password: string;
}

const Workout = (props) => {
  let history = useHistory();

  const handleEdit = () => {
    history.push(`/workout/${props.categoryId}/edit/${props.id}`);
  };

  const activeStatus = props.isActive ? "Active" : "Inactive";
  const dateFormat = new Date(props.createdAt * 1000).toLocaleDateString(
    "en-US"
  );
  return (
    <Row>
      <Col>
        <MainImage src={props.imageUrl} alt="category image" />
        <p>{props.title}</p>
      </Col>
      <Col>
        <p>{props.proName}</p>
      </Col>
      <Col>
        <p> {dateFormat}</p>
      </Col>
      <Col>
        <p> {activeStatus} </p>
      </Col>
      <Col className="last-el">
        <div className="icon">
          <EditIcon onClick={() => handleEdit()} />
          <DeleteWorkout
            categoryId={props.categoryId}
            workoutId={props.id}
            text={null}
          />
        </div>
      </Col>
    </Row>
  );
};

const animatedComponents = makeAnimated();

export const Workouts = (props) => {
  const categoryId = props.match.params.categoryId;

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(
    categoryId !== "all" ? categoryId : "All Workouts"
  );
  const { loading, error, data } = useQuery(GET_WORKOUTS, {
    fetchPolicy: "network-only",
    onCompleted: ({ categories }) => {
      const filterList = categories.map((el) => {
        return { value: el.id, label: el.title };
      });
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) return `Error! ${error}`;

  const view = data.workouts
    .filter((el) => {
      if (filter === "All Workouts") {
        return el.title.toLowerCase().includes(searchTerm);
      } else {
        return (
          el.title.toLowerCase().includes(searchTerm) &&
          el.categoryId === filter
        );
      }
    })
    .map((el) => {
      return (
        <Workout
          id={el.id}
          key={el.id}
          imageUrl={el.imageUrl}
          videoUrl={el.videoUrl}
          title={el.title}
          pro={el.Pro ? el.Pro.id : null}
          proName={el.Pro ? el.Pro.name : null}
          createdAt={el.createdAt}
          isActive={el.isActive}
          categoryId={categoryId}
        />
      );
    });

  if (!data.workouts.length) {
    return (
      <EmptyState
        title="You have no existing Workouts"
        buttonTitle="+ Add New Workout"
        buttonLink={`/workout/${categoryId}/add`}
      />
    );
  }

  const handleChange = ({ target: { name, value } }) => {
    setSearchTerm(value);
  };

  return (
    <Container className="container">
      <FlexRow>
        <TEXT_INPUT
          type="text"
          width={270}
          margin={[20, 0]}
          padding={[8, 0]}
          placeholder="Search Workout"
          name="searchTerm"
          value={searchTerm}
          onChange={handleChange}
          color={theme.WHITE_COLOR}
        />
        <Selector
          fieldname="categoryId"
          field={data ? data.categories : []}
          id={filter}
          manual={false}
          default="All Workouts"
          onUpdate={setFilter}
          name="Workout Category"
          color={theme.WHITE_COLOR}
          marginTop="29px"
        />
      </FlexRow>
      <Table>
        <Head>
          <Row>
            <Col>
              <p>Workout Title</p>
            </Col>
            <Col>
              <p>Alter Pro</p>
            </Col>
            <Col>
              <p>Upload Date</p>
            </Col>
            <Col>
              <p>Active</p>
            </Col>
            <Col className="last-el">
              <ADD_BUTTON to={`/workout/${categoryId}/add`}>
                <p>+ Add New Workout</p>
              </ADD_BUTTON>
            </Col>
          </Row>
        </Head>
        <Body>{view}</Body>
      </Table>
    </Container>
  );
};
const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

export const Table = styled.table`
  text-align: left;
  display: grid;
  border-radius: 18px;
  padding: 23px 25px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);

  .last-el {
    display: flex;
    flex-direction: row;
    justify-self: right;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  & > * {
    margin-right: 28px;
  }
`;

export const ADD_BUTTON = styled(Link)`
  margin-top: -120px;
  width: 186px;
  height: 43px;
  border: none;
  border-radius: 23.8px;
  background-color: #eb506e;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;
