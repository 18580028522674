import { createGlobalStyle } from "styled-components";

import DMSans from "./dmsans-regular-webfont.woff";
import DMSans2 from "./dmsans-regular-webfont.woff2";
import DMSansMedium from "./dmsans-medium-webfont.woff";
import DMSansMedium2 from "./dmsans-medium-webfont.woff2";
import DMSansBold from "./dmsans-bold-webfont.woff";
import DMSansBold2 from "./dmsans-bold-webfont.woff2";

export default createGlobalStyle`
@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), local('DMSans'),
    url(${DMSans2}) format('woff2'),
    url(${DMSans}) format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), local('DMSans'),
    url(${DMSansMedium2}) format('woff2'),
    url(${DMSansMedium}) format('woff');
    font-weight: 500;
}


@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), local('DMSans'),
    url(${DMSansBold2}) format('woff2'),
    url(${DMSansBold}) format('woff');
    font-weight: bold;
}
`;
