const STAGING_URL = "https://api.staging.alterme.com";
const PROD_URL = "https://api.alterme.com";
const DEV_URL = "http://localhost:4000";
let hostname = window && window.location && window.location.hostname;
let backendHost;

switch (hostname) {
  case "localhost":
    backendHost = DEV_URL;
    break;
  case "staging.admin.alterme.com":
    backendHost = STAGING_URL;
    break;
  case "admin.alterme.com":
    backendHost = PROD_URL;
    break;
  default:
    backendHost = STAGING_URL;
}

console.log(backendHost);

export const BACKEND_URL = backendHost;
