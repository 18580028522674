import React from "react";
import { useHistory } from "react-router-dom";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { ReactComponent as BurgerIcon } from "../../../images/burgerIcon.svg";
import { ReactComponent as EditIcon } from "../../../images/ic-edit.svg";
import { Button, Col } from "../../UI";

const Draggable = SortableHandle(() => (
  <Con>
    <Burger />
  </Con>
));

export const SortableCategory = SortableElement(({ value, index, idx }) => {
  let history = useHistory();

  const { id, imageUrl, title, isActive, Workout } = value;
  const handleClick = () => {
    history.push(`/workouts/${id}`);
  };

  const handleEdit = () => {
    history.push(`/category/edit/${id}`);
  };

  const activeStatus = isActive ? "Active" : "Inactive";
  return (
    <>
      <Col>
        <SortIndex>{idx + 1}</SortIndex>
        <Burger />

        <MainImage src={imageUrl} alt="category image" />
        <p>{title}</p>
      </Col>
      <Col>
        <p>{Workout.length}</p>
      </Col>
      <Col>
        <p>{activeStatus}</p>
      </Col>
      <Col>
        <Button onClick={() => handleClick()}>
          <p>View Workouts</p>
        </Button>
        <div className="icon" onClick={() => handleEdit()}>
          <EditIcon />
        </div>
      </Col>
    </>
  );
});

export const NonSortableCategory = ({ value, idx }) => {
  let history = useHistory();

  const { id, imageUrl, title, isActive, Workout } = value;
  const handleClick = () => {
    history.push(`/workouts/${id}`);
  };

  const handleEdit = () => {
    history.push(`/category/edit/${id}`);
  };

  const activeStatus = isActive ? "Active" : "Inactive";
  return (
    <>
      <Col>
        <NonSortIndex></NonSortIndex>
        <MainImage src={imageUrl} alt="category image" />
        <p>{title}</p>
      </Col>
      <Col>
        <p>{Workout.length}</p>
      </Col>
      <Col>
        <p>{activeStatus}</p>
      </Col>
      <Col>
        <Button onClick={() => handleClick()}>
          <p>View Workouts</p>
        </Button>
        <div className="icon" onClick={() => handleEdit()}>
          <EditIcon />
        </div>
      </Col>
    </>
  );
};

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const Burger = styled(BurgerIcon)`
  margin: 12px;
  display: inline;
`;

const Con = styled.span`
  display: inline;
`;

const SortIndex = styled.p`
  width: 25px;
  text-align: center;
  display: block;
`;

const NonSortIndex = styled.p`
  width: 73px;
  text-align: center;
  display: block;
`;
