import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

export interface AppInputProps {
  currency?: boolean;
  percentage?: boolean;
  minutes?: boolean;
  error?: string | false;
  width?: number;
  fontSize?: number;
  textAlign?: string;
  noTopMargin?: boolean;
  displayNoError?: boolean;
  primary?: boolean;
}

export const StyledAppInput = styled.input<AppInputProps>`
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => (props.primary ? 46 : 18)}px;
  background: none;
  border: none;

  margin-top: ${(props) => (!props.title ? (props.noTopMargin ? 0 : 19) : 0)}px;
  /* font-size: ${(props) => (!!props.fontSize ? props.fontSize : 19)}px; */
  font-size: ${(props) => (props.primary ? 16 : 13)}px;
  line-height: 1;
  /* font-family: ${theme.PRIMARY_FONT}; */
  break-inside: avoid;
  /* caret-color: ${theme.PRIMARY_COLOR}; */
  text-align: left;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    opacity: 0.4;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  }
`;

StyledAppInput.defaultProps = {
  noTopMargin: false,
  primary: false,
};

export const StyledFileInput = styled.input<AppInputProps>`
  display: none;
`;

export const FileLabel = styled.label`
  display: inline-block;
  padding: 14px 12px;
  color: #ffffff;
  cursor: pointer;
`;

const AppInputComponent = ({
  primary = false,
  currency = false,
  percentage = false,
  minutes = false,
  displayNoError = false,
  title,
  error,
  ...props
}: AppInputProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <Div width={props.width}>
      {!!title && <InputTitle primary={primary}>{title}</InputTitle>}
      <StyledAppInput
        {...(props as any)}
        onFocus={toggleFocus}
        onBlur={(e) => {
          toggleFocus();
          !!props.onBlur && props.onBlur(e);
        }}
        currency={currency}
        percentage={percentage}
        minutes={minutes}
        title={title}
        error={error}
        primary={primary}
      />
    </Div>
  );
};

interface DivProps {
  width?: number;
}

const Div = styled.div<DivProps>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
  minutes?: boolean;
}

const InputTitle = styled.p<AppInputProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${(props) => (props.primary ? 17.5 : 12)}px;
  color: ${(props) => (props.primary ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  margin: 0;
  margin-bottom: ${(props) => (props.primary ? 15 : 13)}px;
  text-align: center;
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.RED_COLOR};
  margin: 0;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
`;

const AppInput = AppInputComponent;

export { AppInput };
