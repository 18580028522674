import { gql } from "@apollo/client";

export const AdminFragment = gql`
  fragment Admin on User {
    id
    email
    sessionToken
    name
  }
`;

export const CategoryFragment = gql`
  fragment Category on Category {
    id
    title
    isActive
    sortOrder
    Workout {
      id
    }
    imageUrl
  }
`;

export const WorkoutDashboard = gql`
  fragment Workout on Workout {
    id
    title
    description
    isActive
    imageUrl
    videoUrl
    Pro {
      id
      name
    }
    preferredWorkoutLength
    createdAt
    categoryId
  }
`;

export const WorkoutDetail = gql`
  fragment Workout on Workout {
    id
    title
    description
    length
    duration
    isActive
    tag
    imageUrl
    videoUrl
    ProId
    Pro {
      id
      name
    }
    skillLevel
    preferredWorkoutLength
    limitations
    equipments
    categoryId
    Exercises {
      id
      name
      start
      end
      workoutId
    }
  }
`;

export const ProMin = gql`
  fragment Pro on Pro {
    id
    WorkOuts {
      id
    }
    email
    profileImage
    name
    isActive
  }
`;

export const ProDetails = gql`
  fragment Pro on Pro {
    id
    email
    isActive
    introVideo
    introDescription
    introTitle
    profileImage
    bigImage
    specialties
    facebook
    instagram
    twitter
    aboutMe
    quote
    createdAt
    updatedAt
    name
    musicLinks
  }
`;

export const Promotion = gql`
  fragment Promotion on PromotionalItem {
    id
    title
    description
    photo
    isActive
    link
  }
`;

export const Firmware = gql`
  fragment FirmwareContent on FirmwareContent {
    id
    version
    isActive
    fileUrl
    type
    createdAt
  }
`;
