import { useMutation } from "@apollo/client";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DELETE_ONE_PRO, UPDATE_MANY_WORKOUT } from "../../../apollo/mutations";
import { ReactComponent as DeleteIcon } from "../../../images/ic-delete.svg";
import { DELETE_BUTTON, Loading } from "../../UI";

export const DeletePro = ({ proId, text }) => {
  // local set

  let history = useHistory();

  const [deletePro, { loading }] = useMutation(DELETE_ONE_PRO, {
    onCompleted(data) {
      deletePro({
        variables: {
          where: { id: proId },
        },
      });
    },
  });

  const handleDelete = () => {
    deletePro({
      variables: {
        where: {
          id: proId,
        },
      },
    });
  };

  if (loading) return <Loading />;

  if (!text) {
    return <DeleteIcon onClick={handleDelete} />;
  }
  return (
    <DELETE_BUTTON onClick={() => handleDelete()}>
      <FaTrashAlt color="#eb506e" size={11} />
      <p>{text}</p>
    </DELETE_BUTTON>
  );
};
