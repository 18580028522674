import styled from "styled-components";
import { theme } from "../../utils/theme";

export interface InputProps {
  width?: number;
  fontSize?: number;
  color?: string;
  margin?: Array<number>;
  padding?: Array<number>;
}

export const TEXT_INPUT = styled.input<InputProps>`
  background: none;
  border: none;
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 10px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  color: ${(props) => props.color || theme.BLACK_COLOR};
  borderbottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    outline: none;
  }
  & > * {
    background: none;
  }
`;

export const TEXTAREA = styled.textarea<InputProps>`
  background: none;
  border: none;
  resize: none;
  margin: ${(props) =>
    props.margin ? `${props.margin[0]}px ${props.margin[1]}px` : `38px 10px`};
  padding: ${(props) =>
    props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : `10px 5px`};
  width: ${(props) => props.width || 345}px;
  font-family: ${theme.PRIMARY_FONT};
  border-bottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  color: ${(props) => props.color || theme.BLACK_COLOR};
  borderbottom: 1px solid ${theme.INPUT_BORDER_COLOR};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  &:focus {
    outline: none;
  }
`;
