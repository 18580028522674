import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { FileUpload } from "../../Segments/FileUpload";
import { Err, SAVE_BUTTON, Switch, TEXT_INPUT } from "../../UI";
import { DeleteFirmware } from "./DeleteFirmware";
import * as Yup from "yup";

const firmwareSchema = Yup.object().shape({
  version: Yup.string().required("Version is required!"),
  fileUrl: Yup.string().required("Please upload a file"),
});

export const FirmwareForm = (props) => {
  let history = useHistory();

  const { firmwareId, firmware, fileType, firmwareMutation } = props;

  // }
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: firmwareSchema,
    initialValues: {
      id: firmware ? firmware.id : "",
      version: firmware ? firmware.version : "",
      isActive: firmware ? firmware.isActive : false,
      fileUrl: firmware ? firmware.fileUrl : "",
      type: fileType === "APK" ? "apk" : "zip",
    },

    onSubmit: async (values) => {
      if (firmwareId) {
        mutation({
          variables: {
            data: {
              version: { set: values.version },
              isActive: { set: values.isActive },
              fileUrl: { set: values.fileUrl },
            },
            where: { id: values.id },
          },
        });
      } else {
        mutation({
          variables: {
            data: {
              version: values.version,
              isActive: values.isActive,
              fileUrl: values.fileUrl,
              type: values.type,
            },
          },
        });
      }
    },
  });
  // // gql mutation

  const [mutation, { loading }] = useMutation(firmwareMutation, {
    onCompleted(data) {
      if (!data) {
        console.log("error ? data not received ?");
        return;
      }
      history.push(`/firmwares`);
    },
    onError({ message }) {
      return <div>{JSON.stringify(message)}</div>;
    },
  });

  const { errors, touched } = formik;
  return (
    <Container>
      <MainForm onSubmit={formik.handleSubmit}>
        <Section>
          <h2>Add New Firmware .{fileType.toLowerCase()}</h2>
        </Section>
        <Section className="first">
          <div className="flex-row">
            <div className="flex-col">
              {formik.values.version ? <LabelRow>version #</LabelRow> : null}

              <TEXT_INPUT
                type="text"
                width={310}
                margin={[20, 0]}
                padding={[8, 0]}
                placeholder="Version"
                name="version"
                value={formik.values.version}
                onChange={formik.handleChange}
              />
            </div>

            <div className="flex-row-switch">
              <p>Active </p>
              <Switch
                isOn={formik.values.isActive}
                onColor={theme.GREEN_COLOR}
                handleToggle={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
                className={"add-new"}
              />
            </div>
          </div>
          {errors.version && touched.version ? (
            <Err>{errors.version}</Err>
          ) : null}
        </Section>
        <Section className="last">
          <FileUpload formik={formik} fileType={fileType} />
          {errors.fileUrl && touched.fileUrl ? (
            <Err>{errors.fileUrl}</Err>
          ) : null}
        </Section>

        <SubmitSection>
          <div className="flex-col-center">
            <FlexRow>
              <SAVE_BUTTON type="submit">Save Changes</SAVE_BUTTON>
              <SAVE_BUTTON type="button" background={theme.CANCEL_COLOR}>
                Cancel
              </SAVE_BUTTON>
            </FlexRow>
            {firmwareId ? (
              <DeleteFirmware text="Delete Firmware" firmwareId={firmwareId} />
            ) : null}
          </div>
        </SubmitSection>
      </MainForm>
    </Container>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainForm = styled.form`
  border-radius: 4px;
  background-color: #f6f6f6;
  padding-bottom: 50px;

  .flex-row {
    display: flex;
    flex-direction: row;
    & > * {
      margin-right: 20px;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-col-center{
    display: flex;
    flex-direction: column;
    justify-content: center
  }
  .last {
    margin: 57px 51px;
  }
  .flex-row-switch {
    display: flex;
    align-self: end;
    & > * {
      padding: 5px;
    }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 36px 51px;
`;

const SubmitSection = styled.section`
  margin: auto;
  margin-top: 100px;
  margin-bottom: 157px;
  text-align: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
    margin: 26px;
  }
`;
const LabelRow = styled.label`
  display: inline;
  position: absolute;
  margin-top: 10px;
  background: none;
  border: none;
  font-family: DMSans;
  height: 10px;
  opacity: 0.4;
  font-size: 10px;
  font-weight: 500;
`;
