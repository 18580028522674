import React from "react";
import { FirmwareForm } from "./FirmwareForm";
import { CREATE_ONE_FIRMWARE } from "../../../apollo/mutations";
import { useHistory } from "react-router-dom";

export const AddFirmware = (props) => {
  // formik

  const fileType = props.location.state.fileType;

  return (
    <FirmwareForm
      firmwareId={null}
      firmwareMutation={CREATE_ONE_FIRMWARE}
      dateType="Add"
      fileType={fileType}
    />
  );
};
