import { useQuery } from "@apollo/client";
import React from "react";
import { UPDATE_ONE_PRO } from "../../../apollo/mutations";
import { GET_ONE_PRO } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { ProForm } from "./ProForm";

export const EditPro = (props) => {
  const proId = props.match.params.proId;

  const { loading, error, data } = useQuery(GET_ONE_PRO, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        id: proId,
      },
    },
  });

  if (loading) return <Loading />;

  return (
    <ProForm
      proId={proId}
      proMutation={UPDATE_ONE_PRO}
      dateType="Edit"
      pro={data.pro}
    />
  );
};
