import { useMutation, useQuery } from "@apollo/client";
import arrayMove from "array-move";
import React, { useState } from "react";
import { SortableContainer } from "react-sortable-hoc";
import styled from "styled-components";
import { UPDATE_PROMOTIONS_ORDERS } from "../../../apollo/mutations";
import { GET_ALL_PROMOTIONS } from "../../../apollo/queries";
import { theme } from "../../../utils/theme";
import { EmptyState } from "../../Segments/EmptyState";
// import Switch from "../UI";
import { ADD_BUTTON, Body, Col, Head, Loading, Row } from "../../UI";
import { NonSortablePromo, SortablePromo } from "./Promotion";

export const Promotions = (props) => {
  const [activePromotions, setActiveList] = useState([]);
  const [inActivePromotions, setInactiveList] = useState([]);

  const { loading, error, data } = useQuery(GET_ALL_PROMOTIONS, {
    fetchPolicy: "network-only",
    onCompleted: ({ promotionalItems }) => {
      setActiveList(promotionalItems.filter((el: any) => el.isActive === true));
      setInactiveList(
        promotionalItems.filter((el: any) => el.isActive === false)
      );
    },
  });

  const [sortPromotions, { loading: loadingOrders }] = useMutation(
    UPDATE_PROMOTIONS_ORDERS,
    {
      variables: {
        data: [
          ...activePromotions.map((el: any) => el.id),
          ...inActivePromotions.map((el: any) => el.id),
        ],
      },
      onCompleted(data) {
        console.log(data);
      },
    }
  );

  // UPDATE_PROMOTIONS_ORDERS
  if (loading) return <Loading />;
  if (error) return `Error! ${error}`;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setActiveList(arrayMove(activePromotions, oldIndex, newIndex));
    sortPromotions();
  };

  const SortableList = SortableContainer(({ items }) => {
    const sortable = items.filter((el) => el.isActive === true);
    return (
      <Row>
        {sortable.map((value, index) => {
          return (
            <SortablePromo
              key={`item-${value.id}`}
              idx={index}
              index={index}
              value={value}
            />
          );
        })}
      </Row>
    );
  });

  const NonSortableList = ({ items }) => {
    return (
      <Row>
        {items.map((value, index) => (
          <NonSortablePromo
            key={`item-${value.id}`}
            value={value}
            idx={index}
          />
        ))}
      </Row>
    );
  };

  if (!data.promotionalItems.length) {
    return (
      <EmptyState
        title="You have no existing Promotions"
        buttonTitle="+ Add New Promotion"
        buttonLink={{
          pathname: `/promotion/add`,
          state: { promoLen: data.promotionalItems.length + 1 },
        }}
      />
    );
  }

  return (
    <Container className="container">
      <Table>
        <Head>
          <Row>
            <Col>
              <p>Featured Content</p>
            </Col>
            <Col>
              <p>Active</p>
            </Col>
            <Col>
              <p>Link</p>
            </Col>
            <Col className="last-el">
              <ADD_BUTTON
                to={{
                  pathname: `/promotion/add`,
                  state: { promoLen: data.promotionalItems.length + 1 },
                }}
                className="add-margin"
              >
                <p>+ Add New Content</p>
              </ADD_BUTTON>
            </Col>
          </Row>
        </Head>
        <Body>
          <SortableList items={activePromotions} onSortEnd={onSortEnd} />
          <NonSortableList items={inActivePromotions} />
        </Body>
      </Table>
    </Container>
  );
};
const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
`;

const MainImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

export const Table = styled.table`
  text-align: left;
  display: grid;
  border-radius: 18px;
  padding: 23px 25px;
  background-color: #f6f6f6;
  border-collapse: collapse;
  grid-template-columns:
    minmax(100px, 2fr)
    minmax(100px, 2fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);

  .last-el {
    display: flex;
    flex-direction: row;
    justify-self: right;
  }
  .add-margin {
    margin-top: -120px;
  }
`;
