import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as APkFile } from "../../../images/apk.svg";
import { ReactComponent as ZipFile } from "../../../images/zip.svg";
import { theme } from "../../../utils/theme";

// import Switch from "../UI";

export const FirmwareModal = (props) => {
  const { open, onExited, onClose, history } = props;
  const [value, setValue] = React.useState("");

  const [disableStatus, setDisableStatus] = React.useState(true);
  const handleChange = (e) => {
    setValue(e.target.value);
    setDisableStatus(false);
  };

  console.log(history);

  useEffect(() => {
    setValue("");
    setDisableStatus(true);
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();

    history.push(`/firmware/add`, { fileType: value });
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="person-dialog-slide-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Container>
            <h3>Add Firmware</h3>
            <p>Select file type</p>
            <div>
              <ul>
                <li>
                  <RadioInput
                    id="1"
                    type="radio"
                    name="fileType"
                    checked={value === "APK"}
                    value="APK"
                    onChange={handleChange}
                  />
                  <Label htmlFor="1">
                    <IconContainer>
                      <APkFile />
                      <p>apk file</p>
                    </IconContainer>
                  </Label>
                </li>
                <li>
                  <RadioInput
                    id="2"
                    type="radio"
                    name="fileType"
                    value="Zip"
                    checked={value === "Zip"}
                    onChange={handleChange}
                  />
                  <Label htmlFor="2">
                    <IconContainer>
                      <ZipFile />
                      <p>.zip file</p>
                    </IconContainer>
                  </Label>
                </li>
              </ul>
            </div>
          </Container>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <FlexCol>
            <ADD_BUTTON
              type="submit"
              disabled={disableStatus}
              status={!disableStatus}
            >
              Continue
            </ADD_BUTTON>
            <ADD_BUTTON
              type="button"
              onClick={onClose}
              status={!disableStatus}
              cancelButton={true}
            >
              Cancel
            </ADD_BUTTON>
          </FlexCol>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const Container = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  margin: 1px 121px 1px 119px;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
  }
  li {
    list-style-type: none;
  }
  ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const Label = styled.label`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  margin-right: 15px;
`;

const RadioInput = styled.input`
  display: none;
  &:checked + ${Label}:before {
    --border-color: $md-radio-checked-color;
    --animation: ripple 0.2s linear forwards;
  }
  &:checked + ${Label} {
    border: 1px solid black;
    --transform: scale(1);
    --animation: ripple 0.2s linear forwards;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface AddButton {
  status?: boolean;
  cancelButton?: boolean;
}
const ADD_BUTTON = styled.button<AddButton>`
  border: none;
  border-radius: 23.8px;
  background-color: ${(props) =>
    props.cancelButton ? theme.CANCEL_COLOR : theme.SUBMIT_COLOR};
  width: 186px;
  height: 43px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-decoration: none;
  opacity: ${(props) => (props.status ? 1 : 0.5)};
  color: #ffffff;
  p {
    margin: auto;
    font-size: 13px;
  }
  &:focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
  margin-bottom: 15px;
`;
