import styled from "styled-components";
import { theme } from "../../utils/theme";
import swal from "sweetalert2";

export const Err = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: -0.05px;
  color: ${theme.INPUT_BORDER_COLOR};
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  padding-botom: 10px;
`;

export const SwalError = (message) =>
  swal.fire({ title: "Error", text: message });
