import React from "react";
import { ProForm } from "./ProForm";
import { CREATE_ONE_PRO } from "../../../apollo/mutations";

export const AddPro = (props) => {
  // formik

  return (
    <ProForm
      proId={null}
      proMutation={CREATE_ONE_PRO}
      dateType="Add"
      pro={null}
    />
  );
};
