import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { EDIT_ONE_CATEOGRY } from "../../../apollo/mutations";
import { GET_CATEGORY } from "../../../apollo/queries";
import { Loading } from "../../UI";
import { CategoryForm } from "./CategoryForm";

export const EditCategory = (props) => {
  let history = useHistory();

  const categoryId = props.match.params.categoryId;

  const { data, loading, error } = useQuery(GET_CATEGORY, {
    variables: {
      data: {
        id: categoryId,
      },
    },
  });

  if (loading) return <Loading />;

  // render

  return (
    <CategoryForm
      category={data.category}
      categoryId={categoryId}
      catMutation={EDIT_ONE_CATEOGRY}
      exerciseCheck={true}
    />
  );
};
