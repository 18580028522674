import styled from "styled-components";

export const Table = styled.table`
  margin: 30px 44px;
  text-align: left;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(150px, 2fr)
    minmax(150px, 67fr)
    minmax(110px, 1fr)
    minmax(110px, 1fr)
    minmax(150px, 1.67fr);
`;

export const Head = styled.thead`
  display: contents;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.09px;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
`;

export const Body = styled.tbody`
  display: contents;
  font-size: 12px;
  font-weight: 500;
  color: #505050;
  letter-spacing: -0.09px;
  & > tr {
    padding: 14px;
  }
`;

export const Row = styled.tr`
  display: contents;
  padding: 15px;
`;

export const Col = styled.td`
  display: flex;
  padding: 15px 0;
border-bottom: solid 1.5px #e7e7e7;
  & > * {
    display: flex;
    align-self: center;
  }
  & > p {
    margin-left: 10px;
  }
  } ;
`;
